import React from "react";
import { withRouter } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ContentHeader from "./component/content-header";
import "../css/services.scss";

class Services extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  render() {
    const servicesContent = [
      {
        img: "../content/images/our-service-image-1.png",
        titleChi: "展览及各类会议的组织",
        titleEng: "​Organize A Full Live Spectrum Of Events And Activities",
        content:
          "<p>产品展览会，招商引资推介会，产品推介说明会，信息发布会，业务对口洽会，策划组织各类商务考察活动。</p><p>1. Trade exhibitions </br>2. Trade promotion launches </br>3. Product launches and presentations </br>4. Media launches<br/>5. Business Matching arrangements<br/>6. Trade Missions and others events</p>",
      },
      {
        img: "../content/images/our-service-image-2.png",
        titleChi: "招商招展服务",
        titleEng: "​Trade Promotion Services",
        content:
          "<p>随着中马贸易的快速发展，双方的投资贸易交流不断扩大，本公司利用当地的商业网络优势，积极为中国及其它国家的展览会及大型商务活动的招商招展提供代理服务，为中国各省市政府企业的招商引资提供咨询和联络服务。</p><p>With the strong growth of Malaysia-China relations, the bilateral trade between the two countries has increased significantly. Capitalizing on out strong network, we specialized in soliciting exhibitors and promoting trade opportunities for various exhibitions and large-scale trade events organized in China and other countries, while providing advisory and networking services for various Chinese government authorities in attracting trade and investment. </p>",
      },
      {
        img: "../content/images/our-service-image-3.png",
        titleChi: "贸易平台",
        titleEng: "​Trading Platform",
        content:
          "<p>通过发布商业信息，广告，小型推介会等形式为客户寻求代理，推销产品和贸易信息咨询服务。</p><p>​Through media conferences, media advertising and launches, we help clients seek agents, promote products and provide trade information and consultation, ensuring their full success in their endeavors.</p>",
      },
      {
        img: "../content/images/our-service-image-4.png",
        titleChi: "生活旅游配套服务",
        titleEng: "​​Lifestyle travel package services",
        content:
          "<p>中心有长期稳定的合作伙伴公司，为客户安排相尽，周到的食宿交通旅游服务，确保客户整个商务行程的圆满顺。</p><p>​With our trusted long term business partners, we offer tailor-made, full service travel package to our clients to ensure their trips are most hassle- free and enjoyable.</p>",
      },
    ];

    return (
      <div className="services-wrapper">
        <ContentHeader
          contentTitle={"服务 Services"}
          contentBGImg={"services-banner.png"}
        />
        <div className="services-wrapper-content">
          {servicesContent.map((data, index) => {
            return (
              <div
                key={`service-${index + 1}`}
                className={`standard-component service-component`}
              >
                <LazyLoadImage src={data.img} alt={`service-${index + 1}`} />
                <div className="service-component-content">
                  <div className="service-component-title">{data.titleChi}</div>
                  <div className="service-component-title">{data.titleEng}</div>
                  <div
                    className="service-component-content"
                    dangerouslySetInnerHTML={{ __html: data.content }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(Services);
