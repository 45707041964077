import React from "react";
import { withRouter } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ContentHeader from "./component/content-header";
import core from "../controller/core.controller";
import "../css/exhibition.scss";

class Exhibition extends React.Component {
  constructor() {
    super();
    this.state = {
      homeExhibition: [],
    };
  }

  componentDidMount() {
    this.getExhibitionContent();
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  getExhibitionContent() {
    try {
      core.getJsonFile(
        "home-exhibition",
        (data) => {
          if (data?.data) {
            this.setState({ homeExhibition: data.data });
          }

          return;
        },
        () => {
          return;
        }
      );
    } catch {
      return;
    }
  }

  render() {
    const { homeExhibition } = this.state;

    return (
      <div className="exhibition-wrapper">
        <ContentHeader
          contentTitle={"展览 EXHIBITION"}
          contentBGImg={"exhibition-banner.png"}
        />
        <div className="exhibition-wrapper-content">
          {homeExhibition.map((data, index) => {
            return (
              <div
                key={`exhibition-${index + 1}`}
                className={`standard-component exhibition-component`}
              >
                <LazyLoadImage
                  src={data.image}
                  alt={`exbition-banner-${index + 1}`}
                />
                <div className="exhibition-component-title">{data.title}</div>
                <div
                  className="exhibition-component-content"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(Exhibition);
