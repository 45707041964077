import React from "react";
import { withRouter } from "react-router-dom";
import vm from "../controller/contact.controller";
import ContentHeader from "./component/content-header";
import "../css/contact.scss";
import ReCAPTCHA from 'react-google-recaptcha';

let recaptchaRef = React.createRef();
class Home extends React.Component {
  constructor() {
    super();
    vm.init(this);
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  submitForm() {
    recaptchaRef.current.execute();
    vm.onSubmit();
  }

  render() {
    const { fields, errors } = this.state;

    const contactInfo = {
      contact: [
        {
          type: "电邮 E-mail",
          data: ["asianexus2015@gmail.com"],
        },
        {
          type: "联系 Contact Number",
          data: ["TEL: 03-9281 6878", "TEL/FAX: 03-9281 5868"],
        },
        {
          type: "招聘 Career",
          data: ["For job inquiry email to ", "asianexus2015@gmail.com"],
        },
        {
          type: "地址 Address",
          data: [
            "Block B-1-2 (Level 3), ",
            "Menara Uncang Emas, ",
            "85, Jalan Loke Yew, ",
            "55200 Kuala Lumpur, Malaysia.",
          ],
        },
      ],
    };

    return (
      <div className="contact-wrapper">
        <ContentHeader
          contentTitle={"联系我们 Contact Us"}
          contentBGImg={"contact-banner.png"}
        />

        <div className="contact-container">
            <div className={"notification-modal" + (this.state.showNotice ? '' : ' isClose' ) + (this.state.noticeType === 'success' ? ' success-box' : ' error-box')}
              onClick={() => vm.toggleNotification()}
          >
              <div className="notice-content">
                  { this.state.noticeContent }
              </div>
          </div>


          <div className="contact-form-container">
            <div className="contact-form-title">
              <p>若您有任何疑问，请填妥以下表格，我们会尽快与您联系：- </p>
              <p>If you have any inquiries, kindly fill the form below:- </p>
            </div>
            <div className="contact-form">
              <div className="form-field">
                <label>
                  姓名Name <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  name="from_name"
                  value={fields.from_name}
                  placeholder="名字 First Name"
                  onChange={(e) => vm.handleChange(e)}
                />
                <div
                  className={
                    "error-container" +
                    (!errors["from_name"] || errors["from_name"] === ""
                      ? " isHide"
                      : "")
                  }
                >
                  {errors["from_name"]}
                </div>
              </div>

              <div className="form-field">
                <label>
                  电邮Email <span className="mandatory">*</span>{" "}
                </label>
                <input
                  type="text"
                  name="user_email"
                  value={fields.user_email}
                  onChange={(e) => vm.handleChange(e)}
                />
                <div
                  className={
                    "error-container" +
                    (!errors["user_email"] || errors["user_email"] === ""
                      ? " isHide"
                      : "")
                  }
                >
                  {errors["user_email"]}
                </div>
              </div>

              <div className="form-field msg-container">
                <label>
                  信息Message <span className="mandatory">*</span>
                </label>
                <textarea
                  name="message"
                  value={fields.message}
                  onChange={(e) => vm.handleChange(e)}
                ></textarea>
                <div
                  className={
                    "error-container" +
                    (!errors["message"] || errors["message"] === ""
                      ? " isHide"
                      : "")
                  }
                >
                  {errors["message"]}
                </div>
              </div>
              <button className="submit-btn" onClick={() => this.submitForm()}>提交 Submit</button>
            </div>
          </div>
          <div className="contact-info">
            {contactInfo?.contact.map((contact, index) => {
              return (
                <div key={index}>
                  <div className="contact-type">{contact.type}</div>
                  <div className="contact-data-container">
                    {contact?.data.map((data, dIndex) => {
                      return (
                        <div key={dIndex} className="contact-data-p">
                          {data}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ReCAPTCHA
            ref={ recaptchaRef }
            className="recaptcha-container"
            size="invisible"
            sitekey={this.state.reCaptchaKey}
            onChange={(e) => vm.onReCaptchaChange(e)}
        />
      </div>
    );
  }
}
export default withRouter(Home);
