
import { send } from 'emailjs-com';
// import jsonConfig from "webapp/util/jsonConfig";
import PassKey from '../data/passKey';

const controller = {
    vm: null,

    ErrorAllMsg: {
        from_name: {
            require: "请输入你的名字 Please enter your name"
        },
        message: {
            require: "请给我们留言 Please drop some message for us"
        },
        user_email: {
            format: "您的电子邮件格式不正确 Your email format is incorrect",
            require: "请输入您的电子邮件 Please enter your email"
        }
    },

    ErrorCheckType: ["from_name", "message", "user_email"],

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            contactList: [],
            socialMediaList: [],
            reCaptchaKey: PassKey.googleReCaptcha.siteKey,
            contactBG: "",
            fields: {
                from_name: "",
                user_email: "",
                mail_subject: "",
                user_phone: "",
                message: ""
            },
            errors: {},
            disableSubmit: true
        }
    },

    initSettings() {
        // jsonConfig.getServerJson(function (contactJson) {
        //     if (contactJson) {
        //         controller.vm.setState({
        //             contactBG: contactJson.contactBG,
        //             socialMediaList: contactJson.socialMedia,
        //             contactList: contactJson.contact
        //         });
        //     }
        // }, 'contactJson', controller.vm)
    },

    onReCaptchaChange(value) {
        if (value) {
            controller.vm.setState({
                isReCaptchaSuccess: true
            }, () => {
                controller.onSubmit();
            });
        } else {
            controller.openNotificaiton('Google ReCaptcha Failed! Please refresh the page.', 'error');
            controller.resetField();
        }
    },

    handleChange(e = null) {
        let fields = controller.vm.state.fields;
        fields[e.target.name] = e.target.value;

        controller.vm.setState({ fields }, () => {
            controller.handleValidation(e);
        });
    },

    openNotificaiton(msg, noticeType) {
        controller.toggleNotification(msg, noticeType, true);

        setTimeout(() => {
            controller.toggleNotification('', noticeType);
        }, 6000);
    },

    toggleNotification(msg, noticeType, action = false) {
        controller.vm.setState({
            noticeContent: msg,
            noticeType,
            showNotice: action
        });
    },

    handleValidation(e, isCheckAll = false) {
        let errors = controller.vm.state.errors;
        return new Promise ((resolve) => {
            if ((e && e.target && e.target.name) || isCheckAll) {
                let targetName = isCheckAll ? '' : e.target.name;
                if (isCheckAll) {
                    for (let i = 0; i < controller.ErrorCheckType.length; i++) {
                        errors[controller.ErrorCheckType[i]] = controller.handleErrorMsg({
                            target: {
                                name: controller.ErrorCheckType[i],
                                value: isCheckAll ? controller.vm.state.fields[controller.ErrorCheckType[i]] : e.target.value
                            }
                        });
                    }
                } else if (controller.ErrorCheckType.includes(targetName)) {
                    errors[targetName] = controller.handleErrorMsg({
                        target: {
                            name: targetName,
                            value: isCheckAll ? controller.vm.state.fields[targetName] : e.target.value
                        }
                    });
                }
            }
            
            controller.vm.setState({ errors }, () => {
                resolve(controller.handleErrorCheck());
            });
        });
    },

    handleErrorMsg(e) {
        if (e.target.name === "from_name" || e.target.name === "message" || e.target.name === "user_email") {
            if (!(e.target.value && e.target.value !== "")) {
                return controller.ErrorAllMsg[e.target.name].require;
            }
        }

        if (e.target.name === "user_email") {
            if (!e.target.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                return controller.ErrorAllMsg[e.target.name].format;
            }
        }
        return null
    },

    handleErrorCheck() {
        let errors = controller.vm.state.errors;
        if (!controller.vm.state.isReCaptchaSuccess) {
            controller.vm.setState({ disableSubmit: true });
            return false;
        }
        
        for (let i in errors) {
            if (errors[i] && errors[i] !== "") {
                controller.vm.setState({ disableSubmit: true });
                return false;
            }
        }
        
        controller.vm.setState({ disableSubmit: false });
        return true;
    },

    onSubmit() {
        const { fields } = controller.vm.state;
        controller.handleValidation(null, true).then((isProceed) => {
            // if (isProceed) {
                // send(
                //     PassKey.mailKey.serviceKey,
                //     PassKey.mailKey.templateKey,
                //     fields,
                //     PassKey.mailKey.userId
                // )
                // .then(() => {
                    controller.openNotificaiton('留言已收到，我们会尽快回复您。Message has been received, we will get back to you as soon as possible.', 'success');
                    controller.resetField();
                // })
                // .catch(() => {
                //     controller.openNotificaiton('Message deliver failed! Please use social media to contact us.', 'error');
                // });
            // }
        });
    },

    resetField() {
        window.grecaptcha.reset();
        controller.vm.setState({
            fields: {
                from_name: "",
                user_email: "",
                message: ""
            }
        });
    }
}

export default controller;