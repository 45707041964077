import React from "react";
import { withRouter } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import vm from "../controller/home.controller";
import Slider from "react-slick";
import "../css/home.scss";

class Home extends React.Component {
  constructor() {
    super();
    vm.init(this);
  }

  componentDidMount() {
    vm.initCompSettings();
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  render() {
    const { homeBanner, homeExhibition } = this.state;

    const ourServiceContent = [
      {
        img: "../content/images/our-service-image-1.png",
        descChi: "展览及各类会议的组织",
        descEng: "​Organize A Full Live Spectrum Of Events And Activities",
      },
      {
        img: "../content/images/our-service-image-2.png",
        descChi: "招商招展服务",
        descEng: "​Trade Promotion Services",
      },
      {
        img: "../content/images/our-service-image-3.png",
        descChi: "贸易平台",
        descEng: "​Trading Platform",
      },
      {
        img: "../content/images/our-service-image-4.png",
        descChi: "生活旅游配套服务",
        descEng: "​​Lifestyle travel package services",
      },
    ];

    return (
      <div className="home-component">
        <div className="home-component-banner-wrapper">
          <Slider
            ref={(c) => (this.state.refCarousel = c)}
            infinite={true}
            className="home-banner-slider-container"
            dotsClass="slider-dots-container home-banner-slider-dots-container"
            arrows={false}
            dots={true}
            slidesToShow={1}
            slidesToScroll={1}
            appendDots={(indexes) => {
              return (
                <div>
                  {indexes.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        this.state.refCarousel &&
                          this.state.refCarousel.slickGoTo &&
                          this.state.refCarousel.slickGoTo(index, false);
                      }}
                      className={
                        item.props.className === "slick-active"
                          ? "slider-dots slider-active"
                          : "slider-dots"
                      }
                      aria-label={`slide ${index + 1} bullet`}
                    ></div>
                  ))}
                </div>
              );
            }}
          >
            {homeBanner &&
              (homeBanner || []).map((banner, index) => {
                return (
                  <div key={index}>
                    <div className="home-component-banner-container">
                      <LazyLoadImage
                        src={banner?.image}
                        alt={`home-banner=${index}`}
                      />
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
        <div className="standard-component home-component-our-service">
          <div className="standard-header">
            我们的服务 OUR SERVICES{" "}
            <span className="standard-header-bottom-line" />
          </div>
          <div className="standard-body">
            {(ourServiceContent || []).map((content, index) => {
              return (
                <div
                  className="home-component-our-service"
                  key={`our-service-${index + 1}`}
                >
                  <LazyLoadImage
                    src={content.img}
                    className="home-component-our-service-img"
                  />
                  <span className="home-component-our-service-content-chi">
                    {content.descChi}
                  </span>
                  <span className="home-component-our-service-content-eng">
                    {content.descEng}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        {window.screen.width <= 768 && (
          <a className="home-action-button" href="/services">
            了解更多 View More &#10140;
          </a>
        )}
        <div className="standard-component home-component-about-us">
          <div className="standard-header">
            关于我们 ABOUT US
            <span className="standard-header-bottom-line" />
          </div>
          <div className="standard-body">
            {window.screen.width > 768 ? (
              <span>
                Asia Nexus Trade Services Sdn Bhd is located in Kuala Lumpur,
                the capital of Malaysia. It is a professional company dealing
                with Exhibition and business event services. Having ASEAN market
                as an advantage, a close networking with Malaysian government
                and media is our competition advantage. With this advantage,
                Asia Nexus Trade Service could be the bridge for the Chinese
                government departments and all related commissions, creating
                business opportunities, and promote economic corporation for
                both parties. Asia Nexus services including organizing and all
                related services for the Promotion of Exhibition and recruitment
                of Exhibitors, Business Matching events, Business Visit and
                consultation, Travelling services and etc. We held dozens of
                large-scale investment and product promotion and business events
                for China ministries, provinces and cities since the
                establishment of the company in 2010 and being recognized as one
                of the rising star in the industry. There are a few big
                exhibitions on our list: The Malaysia International Export
                Furniture Fair (EFE); Malaysia International Branding Showcase
                (IBS), International Greentech & Eco Products Exhibition &
                Conference Malaysia (iGEM). China (Tianjin) International Solid
                Wood Furniture Exhibition: Singapore Asia Pacific Food Fair;
                China Condiment & Food Ingredients Exhibition (Ministry of
                Agriculture) and so on.
              </span>
            ) : (
              <span>
                Asia Nexus Trade Services Sdn Bhd is located in Kuala Lumpur,
                the capital of Malaysia. It is a professional company dealing
                with Exhibition and business event services. Having ASEAN market
                as an advantage, a close networking with Malaysian government
                and media is our competition advantage.
              </span>
            )}
          </div>
          <div className="standard-body">
            {window.screen.width > 768 ? (
              <span>
                亚洲商联服务中心位于马来西亚首都吉隆坡，在马来西亚政府部门、商界、新闻界建有广泛人脉关系，是一间专业的经贸商业服务公司。公司以东盟市场为依托，面向中国各级政府和商业企业，积极推动双方经济贸易合作。其主要业务范围包括展会招商招展;各种推介会组织安排;商务考察访问，经贸投资咨询顾问；旅游配套服务等。公司自2010年成立以来，先后为中国部委、省市举行过几十次大型招商引资和产品推介活动，受到广泛好评。公司目前代理的主要展会有：马来西亚国际出口家具展（EFE）；马来西亚国际品牌展；马来西亚国际环保展。中国（天津）国际实木家具展览会；新加坡亚太食品展；中国（农业部）调味品展等。借中国“一带一路”发展的强劲东风，亚洲商联服务中心当前各项业务蒸蒸日上，呈现出新的发展活力。
              </span>
            ) : (
              <span>
                亚洲商联服务中心位于马来西亚首都吉隆坡，在马来西亚政府部门、商界、新闻界建有广泛人脉关系，是一间专业的经贸商业服务公司。
              </span>
            )}
          </div>
        </div>

        <div className="standard-component home-component-exhibition">
          <div className="standard-header">
            展览 EXHIBITION
            <span className="standard-header-bottom-line" />
          </div>
          <div className="standard-body">
            <div className="home-component-exhibition-items">
              {homeExhibition.map((data, index) => {
                return (
                  <div
                    className={`home-component-exhibition-item home-component-exhibition-item-${
                      index + 1
                    }`}
                    key={`exhibition-item-${index + 1}`}
                  >
                    <LazyLoadImage
                      src={data.imageSummary}
                      alt={`exhibition-${index + 1}`}
                    />
                    <div className="home-component-exhibition-item-content-container">
                      <div className="home-component-exhibition-item-title">
                        {data.title}
                      </div>
                      <div className="home-component-exhibition-item-content">
                        {data.summary}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <a className="home-action-button" href="/exhibition">
          了解更多 View More &#10140;
        </a>
      </div>
    );
  }
}
export default withRouter(Home);
