import React from "react";
import { withRouter } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ContentHeader from "./component/content-header";
import core from "../controller/core.controller";
import "../css/happenings.scss";

class Happenings extends React.Component {
    constructor() {
        super();
        this.state = {
            happeningsData: [],
        };
    }

    componentDidMount() {
        this.getHappeningsContent();
    }

    componentWillUnmount() {
        this.setState = () => {
        return;
        };
    }

    getHappeningsContent() {
        try {
        core.getJsonFile(
            "happenings",
            (data) => {
            if (data?.data) {
                this.setState({ happeningsData: data.data, happeningsYear: data.years }, () => {
                    this.getMappingContent('ALL');
                });
            }

            return;
            },
            () => {
            return;
            }
        );
        } catch {
        return;
        }
    }

    getMappingContent(year) {
        const { happeningsData } = this.state;
        let happeningsContent = [];

        if (year !== 'ALL') {
            for (let data in happeningsData[year]) {
                happeningsContent.push(happeningsData[year][data]);
            }
        } else {
            for (let rawYear in happeningsData) {
                for (let data in happeningsData[rawYear]) {
                    happeningsContent.push(happeningsData[rawYear][data]);
                }
            }
        }

        this.setState({ happeningsContent, selectedYear: year })
    }

    render() {
        const { happeningsContent, happeningsYear } = this.state;

        return (
            <div className="happenings-wrapper">
                <ContentHeader
                    contentTitle={"新闻 Happenings"}
                    contentBGImg={"happenings-banner.png"}
                />        

                <div className="happenings-container">
                    <div className="years-nav-container">
                        {(happeningsYear || []).map((year, yindex) => {
                            return (
                                <div className={"year-nav" + (this.state.selectedYear === year ? ' isActive' : '')} key={year + yindex} onClick={() => this.getMappingContent(year)}>
                                    {year === 'ALL' ? '全部 ALL' : year}
                                </div>
                            )
                        })}
                    </div>
                    {(happeningsContent || []).map((article, index) => {
                        return (
                            <div key={index}>
                                <div className="article-date">{article.date}</div>
                                <div className="article-content" dangerouslySetInnerHTML={{ __html: article.content }}></div>

                                {[...Array(article.images)].map((raw, imgIndex) => {
                                    let displayIndex = imgIndex + 1;
                                    return (
                                        <LazyLoadImage className="article-img" key={displayIndex} src={'../content/images/happenings/' + article.id + '-' + displayIndex + '.jpg'} alt={article.id + displayIndex} />
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default withRouter(Happenings);
