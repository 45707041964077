const core = {
  jsonFiles: {
    "home-banner": "/configs/home-banner.json",
    "home-exhibition": "/configs/home-exhibition.json",
    "happenings": "/configs/happenings.json",
  },

  readTextFile(file, callback) {
    var rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", file, true);
    rawFile.onreadystatechange = function () {
      if (rawFile.readyState === 4 && rawFile.status === 200) {
        callback(rawFile.responseText);
      } else {
        callback(null);
      }
    };
    rawFile.send(null);
  },

  getJsonFile(fileName, callback) {
    core.readTextFile(core.jsonFiles[fileName], function (text) {
      if (text) {
        callback(JSON.parse(text));
      } else {
        callback(null);
      }
    });
  },
};

export default core;
