
const passKey = {
    mailKey: {
        serviceKey: "service_30mspmo",
        templateKey: "template_17ity8i",
        userId: "pG1TbMQE-BpgPmPWH",
        toName: "MaxG"
    },
    googleReCaptcha: {
        // siteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // testing key
        siteKey: "6LeyBYcpAAAAAOVkbwMGvlrPzQZWdNisW_Fn9-Xf"
    }
}

export default passKey;
