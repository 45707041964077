import styles from '../../css/component/content-header.module.scss';

const ContentHeader = ({ contentTitle, contentBGImg }) => {
    return (
        <div className={`${styles['content-hedaer-container']} content-hedaer-container`} style={{'backgroundImage': "url('/content/images/" + (window.screen.width <= 768 ? 'm-' : '') + contentBGImg + "')"}}>
            <div className={`${styles['content-header-title']} content-header-title`}>
                {contentTitle}
            </div>
        </div>
    );
};

export default ContentHeader;
