import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./css/base.scss";
import Home from "./js/home.js";
import Services from "./js/services.js";
import Exhibition from "./js/exhibition.js";
import Contact from "./js/contact.js";
import Happenings from "./js/happenings.js";

class MainRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideMenuIsOpen: false,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  detectSelectedPage(tab) {
    let currentPath = window.location.pathname;
    let tabSelected = false;

    if (
      (currentPath === "/" && tab.id === "home" && tab.id !== "contactUs") ||
      currentPath.indexOf(tab.id) > -1
    )
      tabSelected = true;

    return tabSelected;
  }

  componentNavBar() {
    const navBarParams = [
      {
        id: "home",
        className: "tab-home",
        context: "主页 Home",
        path: "/",
      },
      {
        id: "services",
        className: "tab-services",
        context: "服务 Services",
        path: "/services",
      },
      {
        id: "exhibition",
        className: "tab-exhibition",
        context: "展览 Exhibition",
        path: "/exhibition",
      },
      {
        id: "happenings",
        className: "tab-happenings",
        context: "新闻 Happenings",
        path: "/happenings",
      },
      {
        id: "contactUs",
        className: "tab-contactUs",
        context: "联系我们 Contact Us",
        path: "/contactUs",
      },
    ];

    return (
      <div
        className={`${window.screen.width <= 768 ? "m-" : ""}nav-bar-container`}
      >
        <div
          className="nav-bar-overlay"
          onClick={() => this.setState({ sideMenuIsOpen: false })}
        ></div>
        <div className={`nav-bar-tabs-container`}>
          {navBarParams.map((tab, index) => {
            return (
              <a
                key={`tab-${index}`}
                className={`nav-bar-tab nav-bar-${tab.className} ${
                  this.detectSelectedPage(tab) ? "selected" : ""
                }`}
                href={tab.path}
              >
                {tab.context}
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.state.sideMenuIsOpen && this.componentNavBar()}
        <div className="header-logo">
          {window.screen.width <= 768 && (
            <LazyLoadImage
              className="header-sidebar-icon"
              src={"../content/images/icon-humburger.png"}
              alt={`logo.png`}
              onClick={() => this.setState({ sideMenuIsOpen: true })}
            />
          )}
          <LazyLoadImage src={"../content/images/logo.png"} alt={`logo.png`} />
        </div>
        {window.screen.width > 768 && this.componentNavBar()}
        <div className="body-content">
          <Switch>
            <Route exact key="home" path="/" component={Home}></Route>
            <Route
              exact
              key="services"
              path="/services"
              component={Services}
            ></Route>
            <Route
              exact
              key="exhibition"
              path="/exhibition"
              component={Exhibition}
            ></Route>
            <Route
              exact
              key="happenings"
              path="/happenings"
              component={Happenings}
            ></Route>
            <Route
              exact
              key="home"
              path="/contactUs"
              component={Contact}
            ></Route>
          </Switch>
        </div>
        <div className="body-footer">
          © 2024 Asia Nexus Trade Services. All right reserved.
        </div>
      </Fragment>
    );
  }
}

export default withRouter(MainRoute);
