import { createRef } from "react";
import core from "./core.controller";

const controller = {
  vm: null,

  init(vm) {
    this.vm = vm;
    this.vm.state = {
      refCarousel: createRef(),
      homeBanner: [],
      homeExhibition: [],
    };
  },

  initCompSettings() {
    controller.getHomeBanner();
    controller.getExhibitionContent();
  },

  getHomeBanner() {
    try {
      core.getJsonFile(
        "home-banner",
        (data) => {
          if (data?.data) {
            controller.vm.setState({ homeBanner: data.data });
          }

          return;
        },
        () => {
          return;
        }
      );
    } catch {
      return;
    }
  },

  getExhibitionContent() {
    try {
      core.getJsonFile(
        "home-exhibition",
        (data) => {
          if (data?.data) {
            controller.vm.setState({ homeExhibition: data.data });
          }

          return;
        },
        () => {
          return;
        }
      );
    } catch {
      return;
    }
  },
};

export default controller;
